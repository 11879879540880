<template>
  <div @click="handleSelectedSeat"  class="seat " :style="{backgroundImage: 'url('+ getSeatBackground +')'}"
       :class="[seatStatus === 'BOOKED' || seatStatus === 'PENDING' ? 'tw-cursor-not-allowed': 'tw-cursor-pointer']">
    <v-card class="tw-cursor-pointer" :disabled="seatStatus=== 'BOOKED'" flat
            style="background: none; border: none; color: #FFFFFF"  >
      {{seatNumber}}
    </v-card>
  </div>
</template>

<script>
import availableSeat from '@/assets/bookedbg.svg'
import selectedSeat from '@/assets/selectedbg.svg'
import bookedSeat from '@/assets/availablebg.svg'
import pendingSeat from '@/assets/pendingSeatBg.svg'
import {mapGetters} from "vuex";
// import {reserveSeats, unReserveSeats} from "@/services/api/APIService"
export default {
  name: "Seat",
  props:{
    seatNumber: [String || Number],
    seatStatus: String
  },
  data(){
    return{
      bookedSeat,
      selectedSeat,
      availableSeat,
      currentStatus : this.seatStatus
    }
  },

  computed:{
    ...mapGetters('booking',['seats']),
    getSeatBackground(){
      let background = availableSeat
        if (this.currentStatus === 'AVAILABLE' ){
          background = availableSeat
        }
        else if (this.currentStatus === 'SELECTED'){
          background = selectedSeat
        }
        else if (this.currentStatus === 'PENDING'){
          background = pendingSeat
        }
        else if (this.currentStatus === 'BOOKED'){
          background =  bookedSeat
        }
      return background
    }
  },
  methods:{
    handleSelectedSeat(){
      // let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
      // let oneWayBookingMinutesTimer = sessionStorage.getItem('oneWayBookingMinutesTimer')
     if (this.currentStatus === 'AVAILABLE' || this.currentStatus === 'SELECTED'){
       let seat ={}
       if (this.currentStatus === 'AVAILABLE'){
         this.currentStatus = 'SELECTED'
         seat.seatNumber = this.seatNumber
         seat.seatStatus = this.currentStatus
         seat.selectedBy = this.generateRandomString()
         seat.travellerDetail={}
         this.$store.dispatch('booking/addSeat',seat)
         this.setSeatTimer('AVAILABLE',this.seats.length)
         // let selectedSeat = [seat];
         // let data = {}
         // data.selectedSeats = selectedSeat
         // data.tripId = userSelectedTrip.id
         // reserveSeats(data).then(()=>{
         //   window.location.reload()
         // })
       }
       else {
         this.currentStatus = 'AVAILABLE'
         seat.seatNumber = this.seatNumber
         seat.seatStatus = this.currentStatus
         seat.selectedBy = null
         seat.travellerDetail={}
         this.$store.dispatch('booking/removeSeat',seat)
         this.setSeatTimer('SELECTED',this.seats.length)
         // let selectedSeat = [seat];
         // let data = {}
         // data.selectedSeats = selectedSeat
         // data.tripId = userSelectedTrip.id
         // unReserveSeats(data).then(()=>{
         //
         //   window.location.reload()
         // })
       }
       this.$emit('selectedSeat',this.seats)
       sessionStorage.setItem("oneWaySeats",JSON.stringify(this.seats))


     }
    },
    generateRandomString(){
      return (Math.random()).toString(36).replace("0.", "");
    },
    setSeatTimer(status, seatsSize){
      if (seatsSize > 0) {
        this.$store.dispatch('booking/setOneWayBookingMinutesTimer', 5)
        this.$store.dispatch('booking/setOneWayBookingShowTimer', true)
        sessionStorage.setItem('oneWayBookingMinutesTimer', JSON.stringify(5))
        sessionStorage.setItem('oneWayBookingShowTimer', JSON.stringify(true))
      }else if(seatsSize === 0){
        this.$store.dispatch('booking/setOneWayBookingMinutesTimer', 0)
        this.$store.dispatch('booking/setOneWayBookingShowTimer', false)
        sessionStorage.removeItem('oneWayBookingMinutesTimer')
        sessionStorage.removeItem('oneWayBookingShowTimer')
      }
    }
  },
  created() {

  }

}
</script>

<style scoped lang="scss">
.seat{
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 15px;
  color: #FFFFFF;
  background-position: center;
  background-size: cover;

}
</style>