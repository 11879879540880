<template>
  <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-8">
    <div class="seat-panel  tw-p-5 ">
      <div class="tw-flex tw-w-full  tw-flex-row tw-mb-8">
        <img :src="steering" alt="booked" class="tw-flex tw-items-end" style="width: 40px; height: 40px" />
        <v-spacer/>
        <seat  :seat-number="totalSeats[1].seatNumber ? totalSeats[1].seatNumber : 0" :seat-status="totalSeats[1].status"
               class="tw-flex tw-items-start" @selectedSeat="handleSelectedSeats" />
      </div>


      <div v-if="totalSeats.length === 8" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 12" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 12)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('secondRow', 12)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')" @selectedSeat="handleSelectedSeats" />
      </div>

      <div v-if="totalSeats.length === 14" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 14)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('secondRow', 14)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('thirdRow', 14)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 15" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 15)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('secondRow', 15)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('thirdRow', 15)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 18" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('secondRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true" :seats-data="getSeatByRow('thirdRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :center-seat="true"  :seats-data="getSeatByRow('fourthRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 24" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('thirdRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('fourthRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('fifthRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length > 24" class="tw-flex  tw-w-full tw-flex-col">

        <back-seat-column :center-seat="true" :seats-data="getSeatByRow('custom')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
    </div>

    <div class="tw-flex tw-w-full lg:tw-w-5/12 tw-flex-col tw-py-10 lg:tw-p-20  ">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="availableSeat" alt="booked" />
        <label class="trip-item tw-ml-10">Available Seats</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="selectedSeat" alt="selected" />
        <label class="trip-item tw-ml-10">Selected Seat</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="pendingSeat" alt="selected" />
        <label class="trip-item tw-ml-10">Pending (Available in less than 5mins)</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <img :src="bookedSeat" alt="available" />
        <label class="trip-item tw-ml-10">Booked Seats</label>
      </div>
    </div>

  </div>
</template>

<script>
import camera from '@/assets/camera.png'
import availableSeat from '@/assets/booked.svg'
import selectedSeat from '@/assets/selected.svg'
import bookedSeat from '@/assets/available.svg'
import pendingSeat from '@/assets/pendingSeat.svg'
import steering from '@/assets/steering.svg'
import Seat from "@/components/reuseables/booking/Seat";
import BackSeatColumn from "@/components/reuseables/booking/BackSeatColumn";
import SeatColumn from "@/components/reuseables/booking/SeatColumn";
import {mapGetters} from "vuex";
export default {
  name: "VehicleSeats",
  components: {SeatColumn, BackSeatColumn, Seat},
  props:{
    trip:Object
  },
  data(){
    return{
      camera,
      availableSeat,
      selectedSeat,
      pendingSeat,
      bookedSeat : bookedSeat,
      steering :steering,

      totalSeats:[],
      selectedSeats :[],

    }
  },
  watch:{
    trip:{
      handler : function (val){
        if (val && val.fleetForThisTrip.seats.length){
          this.availableSeats = []
          val.fleetForThisTrip.seats.forEach(seat => {
            if (seat.status === 'AVAILABLE'){
              this.availableSeats.push(seat)
            }
            this.totalSeats = val.fleetForThisTrip.seats
          })
        }
      },
      immediate: true,
      deep : true
    }
  },
  computed:{
    ...mapGetters("booking",["oneWayTotalAmount"])
  },
  methods:{
    handleSelectedSeats(clickSeat){
      let tripDetail = {}
      let uniqueSeats = new Set()
      tripDetail.tripId = this.trip.id
      tripDetail.seats = clickSeat
      // console.log(tripDetail, "clicked seat")
      this.$store.dispatch("booking/getTotalAmount",this.trip.tripAmount)
      tripDetail.tripAmount = this.oneWayTotalAmount
      this.trip.fleetForThisTrip.seats.forEach(seat => {
        if (seat.seatNumber === clickSeat.seatNumber){
          seat.seatstatus = clickSeat.seatStatus
        }
      })
      clickSeat.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
      tripDetail.seats = []
      uniqueSeats.forEach(seat => tripDetail.seats.push(JSON.parse(seat)) )
      sessionStorage.setItem("tripDetails",JSON.stringify(tripDetail))
      this.$store.dispatch("booking/updateTripAndTravellerDetails",tripDetail)
      this.$emit('getDetails',tripDetail)
      // console.log(tripDetail, "trace heer")

    },
    getSeatByRow(rowType, seater){
      let rowData = [];
      // siena starts
      if (rowType === "firstRow" && seater === 8){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 2 && Number.parseInt(seat.seatNumber) < 6){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 8){
        this.totalSeats.forEach(seat =>{

          if (Number.parseInt(seat.seatNumber) >5 && Number.parseInt(seat.seatNumber) < 9){
            rowData.push(seat)
          }
        })
      }
     // siena ends

     // 12 seater starts
      else if (rowType === "firstRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >2 && Number.parseInt(seat.seatNumber) < 6){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >5 && Number.parseInt(seat.seatNumber) < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater > 11 && seater <14  ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >8 && Number.parseInt(seat.seatNumber) < 12){
            rowData.push(seat)
          }
        })
      }
      // 12 seater ends

      // 14 seater start
      else if (rowType === "firstRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >2 && Number.parseInt(seat.seatNumber) < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >4 && Number.parseInt(seat.seatNumber) < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >7 && Number.parseInt(seat.seatNumber) < 11){
            rowData.push(seat)
          }
        })
      }
      // 14 seater ends

      // 15 seater starts
      else if (rowType === "firstRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >2 && Number.parseInt(seat.seatNumber) < 6){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >5 && Number.parseInt(seat.seatNumber) < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >8 && Number.parseInt(seat.seatNumber) < 12){
            rowData.push(seat)
          }
        })
      }
      // 15 seater ends
      // 18 seater starts
      else if (rowType === "firstRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >2 && Number.parseInt(seat.seatNumber) < 6){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >5 && Number.parseInt(seat.seatNumber) < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >8 && Number.parseInt(seat.seatNumber) < 12){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >11 && Number.parseInt(seat.seatNumber) < 15){
            rowData.push(seat)
          }
        })
      }
      // 18 seater ends
      else if (rowType === "firstRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 2 && Number.parseInt(seat.seatNumber) < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 4 && Number.parseInt(seat.seatNumber) < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 8 && Number.parseInt(seat.seatNumber) < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 12 && Number.parseInt(seat.seatNumber) < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 16 && Number.parseInt(seat.seatNumber) < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "backSeat" ){

        rowData = this.totalSeats.slice(this.totalSeats.length - 4)
      }
      else if (rowType === "custom"){
        rowData = this.totalSeats
      }
      return rowData;
    },
  },
  created() {
    let tripDetails = JSON.parse(sessionStorage.getItem("tripDetails"))
    let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
    let oneWaySeats = JSON.parse(sessionStorage.getItem("oneWaySeats"))
    if(tripDetails && Object.keys(tripDetails).length){
      tripDetails.seats.forEach(storeSeat => {
        this.trip.fleetForThisTrip.seats.forEach(seat => {
          if (seat.seatNumber === storeSeat.seatNumber){
            seat.status = storeSeat.seatStatus
          }
        })
        this.$store.dispatch("booking/addSeat",storeSeat)
      })
      this.$store.dispatch("booking/getTotalAmount",userSelectedTrip.tripAmount)
      this.$store.dispatch("booking/updateTripAndTravellerDetails",tripDetails)
    }
    let uniqueSeats = []
    if (oneWaySeats && oneWaySeats.length){
       oneWaySeats.forEach(seat =>{
         if(uniqueSeats.length){
           uniqueSeats.forEach(uniqueSeat =>{
             if (uniqueSeat.seatNumber !== seat.seatNumber){uniqueSeats.push(seat)}})}
         else {uniqueSeats.push(seat)}})
    }
    uniqueSeats.forEach(seat => this.$store.dispatch("booking/addSeat",seat))
  }
}
</script>

<style scoped lang="scss">
.seat-panel{
  background: #FDFFFC;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  min-width: 250px;
  width: max-content;
  height: max-content;

  @media screen  and (max-width: 1024px){
    margin-top: 20px;
  }
}
.column{
  display: flex;
  flex-direction: column;

}
</style>