<template>
  <div class="tw-w-full">
    <loader :loading="loading" v-if="loading" />

    <div class="step-two" v-else>
      <div>
        <v-btn class="add-pd" @click="$router.push({name:'PaymentDashboard'})">
          <icons name="back" class="mr-2" />
          All Payments
        </v-btn>
      </div>
      <booking-steps />
      <UserSelectedTripInfo />
      <div class="tw-flex tw-justify-center">
        <div class="selectseat ">
          <div class="tw-w-full">
            <seat-timer :tripId="userSelectedTrip.id" />
          </div>
          <vehicle-seats :trip="userSelectedTrip" />
        </div>
      </div>
      <checkout-details />
    </div>
  </div>
</template>

<script>
  import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps.vue'
  import VehicleSeats from '@/components/reuseables/booking/VehicleSeats.vue'
  import CheckoutDetails from '@/components/reuseables/booking/CheckoutDetails.vue'
  import UserSelectedTripInfo from '@/components/reuseables/booking/UserSelectedTripInfo.vue'
  import {
    mapGetters
  } from "vuex";
  import Loader from "@/components/reuseables/Loader";
  import SeatTimer from "@/components/reuseables/booking/SeatTimer";
  import defaultLogo from "@/assets/bluelogo.png"
  import Icons from '../../components/reuseables/Icons.vue'
  import {
    getTripbyId
  } from '../../services/api/APIService';
  export default {
    name: "OneWaySelectSeats",
    components: {
      SeatTimer,
      Loader,
      UserSelectedTripInfo,
      CheckoutDetails,
      BookingSteps,
      VehicleSeats,
      Icons,

    },
    data() {
      return {
        selectedTrip: [],
        selectedTripBtn: false,
        loading: false,
        defaultLogo
      }
    },
    computed: {

      ...mapGetters("booking", ["userSelectedTrip"])
    },
    methods: {
      routeBack() {
        let queryData = {}
        queryData.departure = this.userSelectedTrip.routeDepartureCity.toLowerCase()
        queryData.destination = this.userSelectedTrip.routeDestinationCity.toLowerCase()
        sessionStorage.setItem("queryData", JSON.stringify(queryData))
        this.$router.push({
          name: 'OneWayTrips'
        })
      },
    },
    created() {
      this.loading = true
      let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
      let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
      let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
      if (Object.keys(userSelectedTrip).length) {
        let data = {}
        data.id = userSelectedTrip.id
        getTripbyId(data).then(res => {
          if (res.data.tripRegion === 'crossCountry' && Object.keys(userSelectedCategory).length) {
            res.data.tripAmount = userSelectedCategory.tripAmount
          }
          this.$store.dispatch("booking/setUserSelectedTrip", res.data)
          this.$store.dispatch("booking/getTotalAmount", res.data.tripAmount)
          this.$store.dispatch("booking/updateTripAndTravellerDetails", passengerDetail)
          this.loading = false
        }).catch((error) =>
          this.$displaySnackbar({
            message: error.response.data.details[0] || 'An error occurred',
            success: false
          })
        )
      }

    }
  }
</script>

<style lang='scss' scoped>
  .selectseat {
    width: 70%;
    padding-bottom: 2rem;

    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .back {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    width: max-content;
    text-transform: none;
    cursor: pointer;
  }

  .wrapper {
    width: 100%;
    padding: 0 10rem;

    @media screen and (max-width: 1024px) {
      padding: 1rem;
    }
  }

  .company-logo {
    width: auto;
    height: 40px;
  }
</style>