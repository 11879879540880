<template>
<div class="tw-flex tw-w-full tw-flex-row tw-mb-8 " style="height: auto" v-if="seatsData.length">
  <seat :seat-number="seatsData[0].seatNumber" :seat-status="seatsData[0].status"
        v-on="$listeners" class="tw-mr-8" />
    <seat :seat-number="seatsData[1].seatNumber" :seat-status="seatsData[1].status" class="tw-mr-8"
          v-on="$listeners" />
  <v-spacer v-if="centerSeat"/>

    <seat :seat-number="seatsData[2].seatNumber" :seat-status="seatsData[2].status"
          v-if="seatsData.length >2" v-on="$listeners" :class="[seatsData.length > 3 ? 'tw-mr-8': '']" />

  <seat :seat-number="seatsData[3].seatNumber" :seat-status="seatsData[3].status"
        v-if="seatsData.length >3" v-on="$listeners" :class="[seatsData.length > 4 ? 'tw-mr-8': '']" />

  <seat :seat-number="seatsData[4].seatNumber" :seat-status="seatsData[4].status"
        v-if="seatsData.length >4" v-on="$listeners" />

</div>
</template>

<script>
import Seat from "./Seat";
export default {
  name: "SeatColumn",
  components: {Seat},
  props:{
    seatRow:{
      type: [Number],
      default: 3
    },
    seatsData: Array,
    centerSeat:{
      type: [Boolean],
      default:false
    }
  }
}
</script>

<style scoped>

</style>